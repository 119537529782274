export const config = {
  // api: `${process.env.REACT_APP_API}`,
  title: "Contactez-nous",
  title1: "Nous joindre",
  title2: "Nous joindre",
  title3: "Nous joindre",
  description:
    "Contactez-nous dès maintenant pour vos besoins en services informatiques et web ! Remplissez le formulaire ci-dessous et nous vous contacterons rapidement.",
  successMessage: "Je vous remercie d'avoir pris contact avec moi !",
  //  errorMessage: "Please fill the complete form",
  details: `Contactez notre équipe de professionnels compétents dès maintenant pour tous vos besoins en services informatiques et web. Remplissez simplement le formulaire de contact ci-dessous, et nous vous répondrons dans les plus brefs délais pour discuter de vos projets et vous offrir des solutions adaptées.`,
  thanks: `   Cordialement,
    L'équipe de I-COMS`,
  phone: `06.34.53.80.34`,
  address: `Rue de Bourgogne, Cherbourg en Contentin`,
  zone: `Nous couvrons :  une zone de 20 km aux alentours de Cherbourg`,
  horaire: `Contact par téléphone : 
  - du lundi au vendredi de 9h à 11h et de 14h à 16h, 
  - samedi de 9h a 12h
  - mercredi et dimanche fermé`,
  horaireTravail: `Intervention : 
  - mardi et vendredi de 9h à 12h et de 13h30 à 16h
  - samedi de 9h a 12h`,
  social: ``,
  facebook: ``,
  linkedin: ``,
  instagram: ``,
  youtube: ``,
  whatsup: ``,
  tweeter: ``,
  fields: {
    /* firstName: "",
    lastName: "",*/
    email: "",
    subject: "",
    message: "",
  },
  fieldsConfig: [
    /*{
      id: 1,
      label: "First Name",
      fieldName: "firstName",
      type: "text",
      placeholder: "Prenom",
      isRequired: true,
      classType: "first-name",
    },
    {
      id: 2,
      label: "Last Name",
      fieldName: "lastName",
      type: "text",
      placeholder: "Nom",
      isRequired: true,
      classType: "last-name",
    },*/
    {
      id: 3,
      label: "Email",
      fieldName: "email",
      type: "email",
      placeholder: "Courriel",
      isRequired: true,
      classType: "email",
      error: "Courriel manquant !",
    },
    {
      id: 4,
      label: "Subject",
      fieldName: "subject",
      type: "text",
      placeholder: "Sujet",
      isRequired: true,
      classType: "subject",
      error: "Sujet manquant !",
    },
    {
      id: 5,
      label: "Message",
      fieldName: "message",
      type: "textarea",
      placeholder: "Ecrire une message...",
      isRequired: true,
      classType: "message",
      error: "Message manquant !",
    },
  ],
};
