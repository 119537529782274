const packVData = [
  {
    id: 1,
    packName: "PackV-1",
    packTitle: "Site web vitrine",
    packPrice: "à partir de 500€",
    packDetails: `Nous sommes spécialisés dans la création de sites web vitrines qui mettent en valeur votre entreprise, vos produits et vos services de manière professionnelle et attrayante.

Lorsque vous choisissez notre service de création de site web vitrine, notre équipe dévouée travaille en étroite collaboration avec vous pour comprendre vos besoins et vos objectifs. Nous commençons par une phase de consultation approfondie pour recueillir toutes les informations nécessaires sur votre entreprise, votre public cible et vos attentes en termes de design et de fonctionnalités.

Ensuite, nos concepteurs talentueux utilisent leur expertise en matière de design web pour créer un site web vitrine sur mesure qui correspond à l'identité de votre marque. Nous accordons une grande attention aux détails, en veillant à ce que l'esthétique globale du site soit cohérente et en utilisant des éléments visuels attrayants tels que des images, des vidéos et des graphiques pour captiver les visiteurs.

Nous concevons également une navigation claire et intuitive, permettant aux utilisateurs de trouver facilement les informations qu'ils recherchent. Que ce soit pour présenter vos produits, décrire vos services, afficher des témoignages clients ou partager des informations sur votre entreprise, nous organisons efficacement le contenu de manière à rendre l'expérience utilisateur agréable et engageante.

Nous optimisons également votre site web vitrine pour les moteurs de recherche, en utilisant des techniques d'optimisation SEO pour améliorer sa visibilité en ligne. Cela contribue à attirer davantage de visiteurs qualifiés sur votre site et à augmenter votre présence en ligne.

De plus, nos sites web vitrines sont conçus pour être entièrement responsives, ce qui signifie qu'ils s'adaptent automatiquement à tous les appareils, des ordinateurs de bureau aux smartphones. Ainsi, vos visiteurs peuvent accéder à votre site web de manière optimale, où qu'ils se trouvent.

Enfin, nous vous offrons un support continu pour vous aider à maintenir et à mettre à jour votre site web vitrine au fil du temps. Que ce soit pour ajouter de nouveaux produits, publier des articles de blog ou effectuer des modifications de contenu, nous sommes là pour vous accompagner à chaque étape.

Laissez-nous créer un site web vitrine qui représente au mieux votre entreprise et qui vous aide à atteindre vos objectifs en ligne. Contactez-nous dès aujourd'hui pour discuter de votre projet et commencer à transformer votre vision en réalité numérique.`,
    packVersion: [
      {
        id: 2,
        subPack: "PackV-1a",
        subPackDetails: [
          "Accueil : Une page d'accueil accrocheuse qui donne un aperçu de votre entreprise et de ses principaux avantages.",
          "À propos : Une page qui présente votre entreprise, son histoire, sa mission et ses valeurs.",
          "Services/Produits : Une page décrivant brièvement les services ou les produits que vous proposez.",
          "Galerie : Une page affichant des images ou des visuels représentatifs de votre entreprise ou de vos produits.",
          "Contact : Une page avec vos coordonnées de contact permettant aux visiteurs de vous contacter facilement.",
          "NOTE:\n Le contenu textuel et les images sont fournis par le client. Le développeur web peut apporter de petites modifications ou des ajouts au contenu.\nElle indique que le client fournit le contenu initial sous forme de textes et d'images, mais le développeur web a la possibilité d'apporter de légères modifications ou ajouts à ce contenu pour optimiser le site web. Cela peut inclure des ajustements pour améliorer la mise en page, l'organisation du contenu ou l'optimisation pour les moteurs de recherche. ",
        ],
        subPackPrice: "500€",
      },
      {
        id: 3,
        subPack: "PackV-1b",
        subPackDetails: [
          "Accueil : Une page d'accueil attrayante avec des éléments visuels et des appels à l'action bien définis, tels que des sliders, etc",
          "À propos : Une page approfondie présentant en détail votre entreprise, votre équipe, vos valeurs et votre expertise.",
          "Services/Produits : Des pages dédiées à chaque service ou produit que vous proposez, avec des descriptions détaillées et des visuels attractifs.",
          "Portfolio : Une galerie étendue mettant en valeur vos réalisations, vos projets ou vos produits.",
          "Intégration des médias sociaux : Des liens vers vos profils sur les réseaux sociaux, permettant aux visiteurs de vous suivre et de partager votre contenu.",
          "Contact : Formulaire de contact avancé : Un formulaire de contact plus élaboré, incluant des champs personnalisés ou des options de sélection pour une meilleure segmentation des demandes.",
          "NOTE:\n Outre le contenu textuel et les images fournis par le client, des textes et des images peuvent être ajoutés par le développeur.\n Le développeur du site web peut également ajouter du contenu textuel et des images selon les besoins du projet. Cela peut inclure l'ajout de textes supplémentaires pour améliorer la clarté et la cohérence du contenu, ainsi que l'incorporation d'images pertinentes pour enrichir la présentation visuelle du site. Il est courant que les développeurs web apportent des modifications ou des ajouts au contenu fourni par le client afin d'optimiser le site web et offrir une meilleure expérience utilisateur.",
        ],
        subPackPrice: "1500€",
      },
      /*{ id: 4, subPack: "PackV-1c", subPackDetails: "", subPackPrice: "" },*/
    ],
  },
];

export default packVData;
