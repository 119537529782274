import React, { useState } from "react";
import SecondNavBar from "../components/SecondNavBar";
import mainData from "../data/mainData";
import servicesData from "../data/servicesData";
import Button from "../components/Button";

const Services = () => {
  let [showMore, setShowMore] = useState(true);
  const { serviceTitle, serviceSubTitle, serviceDescription } = mainData;

  return (
    <div className="services">
      <div className="services-container">
        <SecondNavBar showMore={showMore} data={"service"} />
        <div className={showMore === false ? "services-box-container" : "out"}>
          <h1 className="services-title-top">{serviceTitle}</h1>
          <h3>{serviceSubTitle}</h3>
          <div className="services-cube"></div>
          <div className="services-cube-right"></div>
          <div className="services-cube-left"></div>
          <div className="services-cube-bottom"></div>
          <div className="services-button">
            <button onClick={() => setShowMore(true)}>Découvrir</button>
          </div>
        </div>

        <div
          className={
            showMore === true ? "service-box-offers-container" : "hide"
          }
        >
          <div className="service-intro-container">
            <div className="service-offers-intro">
              <h1>{serviceTitle}</h1>
              <h3 className="new-line">{serviceDescription}</h3>
              <div className="service-cube-offers-intro"></div>
            </div>
          </div>
          <div className="service-offers-bottom">
            {servicesData
              .filter((filterData) => filterData.id <= 3)
              .map((getData) => (
                <div key={getData.id} className="elements">
                  <div className="web-cube-offers"></div>
                  <h1 className="one">{getData.packTitle}</h1>
                  {getData.subPack.map((getData, index) => (
                    <div key={getData.id + index} className="details">
                      <h3 className="title">{getData.title}</h3>
                      <h3>{getData.details}</h3>
                    </div>
                  ))}
                  <Button packLink={getData.packLink} />
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
