const webData = [
  {
    id: "1",
    packageName: "PK-V",
    packageTitle: "Site web vitrine",
    packageSubTitle: "Entre 5 et 10 pages",
    packageDetails: [
      "À propos",
      "Services/Produits",
      "Galerie/Portfolio",
      "Avis clients",
      "Contact",
    ],
    packagePrice: "à partir de 500€",
    packageClass: "web-box-container-right one",
    packageButton: "Découvrir",
    packageLink: "creation",
  },
  {
    id: "2",
    packageName: "PK-P",
    packageTitle: "Site web personnalisée",
    packageSubTitle: "",
    packageDetails: [
      `Site web personnalisé selon vos besoins et vos préférences. Nous
            concevons un site web unique qui reflète l'identité de votre
            entreprise et met en valeur vos produits ou services.`,
    ],
    packagePrice: "prix sur demande",
    packageClass: "web-box-container-right two",
  },
  {
    id: "3",
    packageName: "PK-I",
    packageTitle: "Site web institutionnel",
    packageSubTitle: "",
    packageDetails: [
      `Présentez votre organisation de manière professionnelle grâce à un
            site web institutionnel. Créez une présence en ligne solide avec un
            site web institutionnel sur mesure.`,
    ],
    packagePrice: "prix sur demande",
    packageClass: "web-box-container-right three",
  },
  {
    id: "4",
    packageName: "PK-R",
    packageTitle: "Refonte de site web",
    packageSubTitle: "",
    packageDetails: [
      `Donnez une nouvelle vie à votre site web avec une refonte complète.
            Modernisez son design, améliorez sa fonctionnalité et offrez une
            expérience utilisateur optimale.`,
    ],
    packagePrice: "à partir de 500€",
    packageClass: "web-box-container-right four",
  },
  {
    id: "5",
    packageName: "PK-M",
    packageTitle: "Maintenance web",
    packageSubTitle: "",
    packageDetails: [
      "Mises à jour régulières",
      "Sauvegardes régulières",
      "Sourveillance sécuritaire",
      "Optimisation de site",
      "Support technique",
    ],
    packagePrice: "à partir de 69€",
    packageClass: "web-box-container-right five",
    packageButton: "Découvrir",
    packageLink: "maintenance",
  },
];
export default webData;
