import React from "react";
import Slider from "../components/Swiper.jsx";

const Main = () => {
  return (
    <main>
      <Slider />
    </main>
  );
};

export default Main;
