import React from "react";
import { useNavigate } from "react-router-dom";

const Button = ({ ...props }) => {
  const navigate = useNavigate();
  return (
    <div className="button-container">
      <button onClick={() => navigate(`${props.packLink}`)}>Découvrir</button>
    </div>
  );
};

export default Button;
