import React, { useState } from "react";
import SecondNavBar from "../components/SecondNavBar.jsx";
import contractsData from "../data/contractsData.js";
import packMData from "../data/packMData.js";
import tarifsMData from "../data/tarifsMData.js";

const PackM = () => {
  const [showPacks, setShowPacks] = useState(false);
  const [showPrices, setShowPrices] = useState(false);
  // const [showTypes, setShowTypes] = useState(false);
  const [showMain, setShowMain] = useState(true);

  return (
    <div className="pack">
      <SecondNavBar showMore={true} data={"web"} />
      <div
        className={
          ("pack-container",
          showMain === true ? "pack-container" : "pack-container off")
        }
      >
        <div className="pack-middle-elements">
          <div className="web-cube-offers title"></div>
          <div className="top-element">
            {contractsData
              .filter((filterData) => filterData.id === 2)
              .map((getData) => {
                return (
                  <div key={getData.id} className="elements-left">
                    <h1>{getData.titleContract}</h1>
                    <h3>Contenu du contrat</h3>
                    {getData.detailsContract.map((getDetails, index) => {
                      return <p key={index}>{getDetails}</p>;
                    })}
                  </div>
                );
              })}
          </div>
          <div className="web-button">&nbsp;</div>
        </div>
        <div className="pack-middle-elements">
          <div className="web-cube-offers"></div>
          <div className="top-element">
            {contractsData
              .filter((filterData) => filterData.id === 3)
              .map((getData) => {
                return (
                  <div key={getData.id} className="elements-middle-left">
                    <h1>&nbsp;</h1>
                    <h3>Méthodes de facturation</h3>
                    {getData.detailsContract.map((getDetails, index) => {
                      return <p key={index}>{getDetails}</p>;
                    })}
                    <h1>&nbsp;</h1>
                    <p className="extra">{getData.extra}</p>
                  </div>
                );
              })}
          </div>
          {/*<div className="web-button">
            <button
              onClick={() => {
                setShowTypes(true);
                setShowMain(false);
              }}
            >
              Découvrir
            </button>
            </div>*/}
        </div>
        <div className="pack-middle-elements">
          <div className="web-cube-offers"></div>
          <div className="top-element">
            {contractsData
              .filter((filterData) => filterData.id === 4)
              .map((getData) => {
                return (
                  <div key={getData.id} className="elements-middle-right">
                    <h1>&nbsp;</h1>
                    <h3>Types de contrats</h3>
                    {getData.detailsContract.map((getDetails, index) => {
                      return <p key={index}>{getDetails}</p>;
                    })}
                    <h1>&nbsp;</h1>
                  </div>
                );
              })}
          </div>
          <div className="web-button">
            <button
              onClick={() => {
                setShowPacks(true);
                setShowMain(false);
              }}
            >
              Découvrir
            </button>
          </div>
        </div>
        <div className="pack-middle-elements">
          <div className="web-cube-offers"></div>
          <div className="top-element">
            {contractsData
              .filter((filterData) => filterData.id === 5)
              .map((getData) => {
                return (
                  <div key={getData.id} className="elements-right">
                    <h1>&nbsp;</h1>
                    <h3>Plans tarifaires individuels</h3>
                    {getData.detailsContract.map((getDetails, index) => {
                      return <p key={index}>{getDetails}</p>;
                    })}
                    <h1>&nbsp;</h1>
                  </div>
                );
              })}
          </div>
          <div className="web-button">
            <button
              onClick={() => {
                setShowPrices(true);
                setShowMain(false);
              }}
            >
              Découvrir
            </button>
          </div>
        </div>
      </div>
      <div className={showPacks === true ? "pack-container packs" : "hide"}>
        <div className="back-arrow">
          <span
            className="material-icons"
            onClick={() => {
              setShowPacks(false);
              setShowMain(true);
            }}
          >
            arrow_back
          </span>
        </div>
        <div className="packs-elements">
          <div className="web-cube-offers title"></div>
          <div className="packs-title">
            <h1>Types de contrats</h1>
          </div>
          <div className="packs-details">
            {packMData.map((getPackData) => {
              return (
                <div key={getPackData.id} className="details-container ">
                  <h3>
                    {getPackData.packName}
                    <span className="title">- {getPackData.packTitle} -</span>
                    <span className="price">{getPackData.packPrice}</span>
                  </h3>
                  <p>{getPackData.packDetails}</p>
                  {getPackData.id >= 4 &&
                    getPackData.extra.map((getData, index) => (
                      <p key={index} className="extra one">
                        {getData}
                      </p>
                    ))}
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div
        className={
          ("pack-container", showPrices === true ? "pack-container" : "hide")
        }
      >
        <div className="back-arrow">
          <span
            className="material-icons"
            onClick={() => {
              setShowPrices(false);
              setShowMain(true);
            }}
          >
            arrow_back
          </span>
        </div>
        <div className="packs-elements">
          <div className="web-cube-offers title"></div>
          <div className="packs-title">
            <h1>Plan tarifaire individuel</h1>
          </div>
          <div className="packs-details">
            {tarifsMData.map((getData) => {
              return (
                <div key={getData.id} className="details-container ">
                  <h3>
                    {getData.packName}
                    <span className="title"> - {getData.packTitle} -</span>
                    <span className="price">
                      {getData.packPrice}
                      &nbsp;{getData.packCount}
                    </span>
                  </h3>
                  <p>{getData.packDetails}</p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PackM;
