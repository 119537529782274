import SecondNavBar from "../components/SecondNavBar.jsx";
import { hardwareData, hardwareTopData } from "../data/hardwareData.js";

const Hardware = () => {
  const { hardwareTitle, hardwareDescription } = hardwareTopData;
  return (
    <div className="hardware">
      <SecondNavBar showMore={true} data={"service"} />
      <div className="hardware-top-container">
        <div className="elements">
          <div className="service-cube-offers"></div>
          <article>
            <h1>{hardwareTitle}</h1>
            <h3>{hardwareDescription}</h3>
          </article>
        </div>
      </div>
      <div className="hardware-bottom-container">
        {hardwareData.map((getData) => (
          <div key={getData.id} className={`elements ${getData.order}`}>
            <div className="service-cube-packs"></div>
            <article>
              <h1>{getData.packTitle}</h1>
              <h3 className="title">
                {getData.packName} - <span>{getData.packPrice}</span>
              </h3>
              {getData.extra.map((getData, index) => (
                <p key={index}>{getData}</p>
              ))}
            </article>
            {/*}
            <div className="hardware-button">
              <button>Découvrir</button>
            </div>*/}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Hardware;
