import { useState } from "react";
//import { useNavigate } from "react-router-dom";
import SecondNavBar from "../components/SecondNavBar.jsx";
import {
  maintenanceData,
  maintenanceTopData,
} from "../data/maintenanceData.js";

const Maintenance = () => {
  const { maintenanceTitle, maintenanceDescription } = maintenanceTopData;
  const [selectId, setSelectId] = useState(0);
  //const navigate = useNavigate();

  return (
    <div className="maintenance">
      <div className={selectId >= 1 ? "back-arrow on" : "back-arrow"}>
        <span
          className="material-icons"
          onClick={() => {
            setSelectId(0);
          }}
        >
          arrow_back
        </span>
      </div>

      <div
        className={selectId >= 1 ? "back-arrow on" : "back-arrow"}
        onClick={() => {
          setSelectId(0);
        }}
      >
        <span className="material-icons">arrow_back</span>
      </div>

      <SecondNavBar showMore={true} data={"service"} />
      <div className="maintenance-top-container">
        <div className="elements">
          <div className="service-cube-offers"></div>
          <article>
            <h1>{maintenanceTitle}</h1>
            <h3>{maintenanceDescription}</h3>
          </article>
        </div>
      </div>
      <div className={selectId === 0 ? "maintenance-middle-container" : "hide"}>
        {maintenanceData.map((getData) => (
          <div key={getData.id} className={`elements ${getData.order}`}>
            <div className="service-cube-packs"></div>
            <article>
              <h1>{getData.packTitle}</h1>
              <h3 className="title">{getData.packName}</h3>
              <p>{getData.packDetails}</p>
            </article>
            <div className="service-button">
              <button onClick={() => setSelectId(getData.id)}>Découvrir</button>
            </div>
          </div>
        ))}
      </div>
      <div className={selectId >= 1 ? "maintenance-bottom-container" : "hide"}>
        {maintenanceData
          .filter((filterData) => filterData.id === selectId)
          .map((getData) => (
            <div key={getData.id} className="elements">
              <div className="service-cube-packs"></div>
              <article>
                <h1>{getData.packTitle}</h1>
                <h3 className="title">
                  {getData.packName} - <span>{getData.packPrice}</span>
                </h3>
                <p className="no-arrow">{getData.packDetails}</p>
                {getData.extra.map((getData, index) => (
                  <p key={index} className={index <= 0 ? "set" : ""}>
                    {getData}
                  </p>
                ))}
              </article>
            </div>
          ))}
      </div>
    </div>
  );
};

export default Maintenance;
