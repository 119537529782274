const servicesData = [
  {
    id: 1,
    packName: "PackSV-L",
    packTitle: "Dépannage logiciel",
    packPrice: "49.99€/h",
    packCount: "",
    packLink: "/informatique/services/logiciel",
    packDetails: `Résolution de problèmes liés aux logiciels, notamment les erreurs système, les plantages, les problèmes de compatibilité, les infections par des logiciels malveillants, etc.`,
    subPack: [
      {
        id: "l1",
        title: "1. Résolution d'erreurs système",
        details: `Les techniciens analysent les messages d'erreur et les problèmes de stabilité du système pour identifier les causes sous-jacentes et les résoudre. Cela peut inclure la correction de fichiers système corrompus, de conflits logiciels, de pilotes obsolètes, etc.`,
      },
      {
        id: "l2",
        title: "\n2. Suppression de logiciels malveillants",
        details: ` Si votre ordinateur est infecté par des virus, des logiciels espions, des ransomwares ou d'autres types de logiciels malveillants, les techniciens peuvent effectuer des analyses antivirus approfondies et éliminer ces menaces pour restaurer la sécurité de votre système.`,
      },
      {
        id: "l3",
        title: "\n3. Résolution de problèmes d'application",
        details: `Si vous rencontrez des problèmes avec des applications spécifiques, comme des plantages fréquents, des erreurs de chargement ou des fonctionnalités manquantes, les techniciens peuvent diagnostiquer et résoudre ces problèmes.`,
      },
      {
        id: "l4",
        title: "\n4. Mise à jour et correctifs",
        details: `Les techniciens peuvent vérifier les mises à jour logicielles disponibles pour votre système d'exploitation et les applications installées, et les installer pour corriger les bogues connus et améliorer les performances.`,
      },
      {
        id: "l5",
        title: "\n5. Configuration logicielle",
        details: `Si vous avez des difficultés à configurer correctement un logiciel ou une application spécifique, les techniciens peuvent vous aider à effectuer les réglages nécessaires pour garantir son bon fonctionnement.`,
      },
      {
        id: "l6",
        title: "\n6. Récupération de données",
        details: `En cas de perte de données due à un problème logiciel, les techniciens peuvent utiliser des outils spécialisés pour récupérer les fichiers perdus ou endommagés.`,
      },
      {
        id: "l7",
        title: "\n7. Optimisation des performances",
        details: `Les techniciens peuvent effectuer des ajustements et des optimisations pour améliorer les performances de votre système, tels que l'optimisation des paramètres, la suppression des fichiers temporaires inutiles, etc.`,
      },
    ],
  },
  {
    id: 2,
    packName: "PackSV-M",
    packTitle: "Dépannage matériel",
    packPrice: "49.99€/h",
    packCount: "",
    packLink: "/informatique/services/materiel",
    packDetails: `Réparation et résolution de problèmes liés au matériel informatique, tels que les ordinateurs, les imprimantes, les périphériques, les serveurs, etc.`,
    subPack: [
      {
        id: "m1",
        title: "1. Diagnostic des problèmes matériels",
        details: `Les techniciens effectuent des tests et des analyses approfondis pour identifier la cause exacte du problème matériel, qu'il s'agisse d'un ordinateur, d'une imprimante, d'un périphérique ou d'un serveur.`,
      },
      {
        id: "m2",
        title: "\n2. Réparation et remplacement des composants défectueux",
        details: `Une fois le problème identifié, les techniciens peuvent réparer ou remplacer les composants matériels défectueux. Cela peut inclure le remplacement d'une carte mère, d'un disque dur, d'une mémoire RAM, d'un écran, d'un clavier, d'une alimentation, etc.`,
      },
      {
        id: "m3",
        title: "\n3. Mise à niveau matérielle",
        details: `Les techniciens peuvent effectuer des mises à niveau matérielles pour améliorer les performances de l'ordinateur, tels que l'ajout de mémoire RAM supplémentaire, le remplacement d'un disque dur par un SSD, l'installation d'une carte graphique plus puissante, etc.`,
      },
      {
        id: "m4",
        title: "\n4. Nettoyage et entretien",
        details: `Les techniciens peuvent effectuer un nettoyage en profondeur de l'ordinateur pour éliminer la poussière et les débris accumulés, ce qui peut affecter les performances et la stabilité du système`,
      },
      {
        id: "m5",
        title: "\n5. Assistance pour les problèmes de connectivité ",
        details: `Si vous rencontrez des problèmes de connectivité, comme des problèmes de Wi-Fi, des problèmes de connexion Internet ou des problèmes de réseau, les techniciens peuvent vous aider à les résoudre.`,
      },
      {
        id: "m6",
        title: "\n6. Installation de périphériques",
        details: `Si vous avez besoin d'installer de nouveaux périphériques, tels qu'une imprimante, un scanner, un appareil photo, un moniteur, etc., les techniciens peuvent vous aider à les configurer correctement et à les faire fonctionner.`,
      },
    ],
  },
  {
    id: 3,
    packName: "PackSV-A",
    packTitle: "Maintenance informatique",
    packPrice: "49.99€/h",
    packCount: "",
    packLink: "/informatique/services/maintenance",
    packDetails: ` Dans le monde numérique d'aujourd'hui, il est essentiel de maintenir vos systèmes informatiques en bon état de fonctionnement. Notre service de dépannage et de maintenance informatiques vous offre la tranquillité d'esprit en vous assurant une assistance rapide et fiable en cas de problèmes techniques. Que ce soit pour résoudre des pannes matérielles, des erreurs logicielles ou des problèmes de réseau, notre équipe d'experts est là pour vous aider. Nous comprenons l'importance de la continuité de vos activités, c'est pourquoi nous mettons tout en œuvre pour minimiser les interruptions et rétablir rapidement vos systèmes. Avec notre service de dépannage et de maintenance informatiques, vous pouvez vous concentrer sur votre cœur de métier en laissant les experts prendre soin de votre infrastructure technologique.`,
    subPack: [
      {
        id: "a1",
        title: "",
        details: `La maintenance informatique est essentielle pour assurer la stabilité, la performance et la sécurité de vos systèmes informatiques. Notre service de maintenance informatique vous offre une solution complète pour préserver la fiabilité de votre infrastructure technologique.`,
      },
      {
        id: "a2",
        title: "",
        details: `\nNotre équipe d'experts en maintenance informatique est formée pour effectuer des vérifications régulières, des mises à jour et des réglages afin de garantir le bon fonctionnement de vos équipements, logiciels et réseaux. Nous surveillons attentivement les performances de vos systèmes et intervenons rapidement pour prévenir les pannes, les erreurs et les vulnérabilités de sécurité.`,
      },
      {
        id: "a3",
        title: "",
        details: `\nQue ce soit pour la maintenance préventive, la résolution des problèmes courants, l'optimisation des performances ou la gestion des mises à jour, notre équipe est là pour vous offrir une assistance fiable et professionnelle. Nous comprenons l'importance de maintenir vos activités en marche sans interruption, c'est pourquoi nous mettons tout en œuvre pour minimiser les temps d'arrêt et maximiser la productivité de votre entreprise.`,
      },
      {
        id: "a4",
        title: "",
        details: `\nAvec notre service de maintenance informatique, vous pouvez avoir l'assurance que vos systèmes sont pris en charge par des experts qui veillent à ce qu'ils fonctionnent de manière optimale. Concentrez-vous sur votre cœur de métier et laissez-nous prendre soin de votre infrastructure informatique, afin que vous puissiez bénéficier d'une performance optimale et d'une tranquillité d'esprit totale.`,
      },
    ],
  },
  {
    id: 4,
    packName: "PackSV-W",
    packTitle: "Réseau et connectivité",
    packPrice: "49.99€/h",
    packCount: "",
    packDetails: `Résolution des problèmes liés aux réseaux informatiques, tels que les problèmes de connexion Internet, les problèmes de configuration réseau, les problèmes de Wi-Fi, etc.`,
  },
  {
    id: 5,
    packName: "PackSV-R",
    packTitle: "Récupération de données",
    packPrice: "49.99€/h",
    packCount: "",
    packDetails: `Récupération des données perdues ou endommagées en cas de défaillance du disque dur, de suppression accidentelle, de corruption du système de fichiers, etc.`,
  },
  {
    id: 6,
    packName: "PackSV-M",
    packTitle: "Maintenance et optimisation",
    packPrice: "49.99€/h",
    packCount: "",
    packDetails: `Services de maintenance régulière pour assurer le bon fonctionnement des systèmes informatiques, ainsi que l'optimisation des performances pour améliorer l'efficacité et la vitesse.`,
  },
  {
    id: 7,
    packName: "PackSV-T",
    packTitle: "Support technique à distance",
    packPrice: "49.99€/h",
    packCount: "",
    packDetails: `Assistance à distance pour résoudre les problèmes informatiques via une connexion Internet sécurisée, sans nécessiter la présence physique d'un technicien.`,
  },
  {
    id: 8,
    packName: "PackSV-SR",
    packTitle: "Sauvegarde et récupération",
    packPrice: "49.99€/h",
    packCount: "",
    packDetails: `Mise en place de stratégies de sauvegarde régulières pour protéger les données importantes et la mise en œuvre de plans de récupération en cas de perte de données.`,
  },
  {
    id: 9,
    packName: "PackSV-A",
    packTitle: "Assistance aux utilisateurs",
    packPrice: "49.99€/h",
    packCount: "",
    packDetails: `Aide et conseils aux utilisateurs finaux pour résoudre les problèmes courants, répondre aux questions et fournir une assistance technique générale.`,
  },
];
export default servicesData;
