import React, { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";

const ContactForm = ({ ...props }) => {
  const [buttonState, setButtonState] = useState("disabled");
  const recaptchaRef = React.useRef();

  const onSubmitWithReCAPTCHA = async (e) => {
    e.preventDefault();
    const token = await recaptchaRef.current.executeAsync();
    token.current.reset();
  };

  /*
  const verifyToken = async (token) => {
    try {
      let response = await axios.post(
        `http://localhost:3000/contact`,
        {
          secret: process.env.REACT_APP_SECRET_KEY,
          token,
        },
        console.log(token)
      );
      return response.data;
    } catch (error) {
      console.log("error ", error);
    }
  };

  const onSubmitWithReCAPTCHA = async (e) => {
    const token = await recaptchaRef.current.executeAsync();
    recaptchaRef.current.reset();
    if (token) {
      let valid_token = await verifyToken(token);
      if (valid_token.success) {
        console.log("ok");
      } else {
        console.log("No Token");
      }
    }
  };
*/

  return (
    <div className="contact-form">
      <form
        action="?"
        method="POST"
        onSubmit={(e) => {
          props.handleSubmit(e);
          props.validateFields(e);
          //   onSubmitWithReCAPTCHA();
        }}
        id="form-reset"
      >
        {props.fieldsConfig &&
          props.fieldsConfig.map((field) => {
            return (
              <div key={field.id}>
                {field.type !== "textarea" ? (
                  <div>
                    <label>{/*field.label*/}</label>
                    <input
                      type={field.type}
                      className={field.classType}
                      placeholder={field.placeholder}
                      name={field.fieldName}
                      value={field.name}
                      data-check={field.isRequired}
                      onChange={(e) => {
                        props.validateFields(e);
                        props.handleForm(e);
                        onSubmitWithReCAPTCHA(e);
                      }}
                    />
                    <div className="error-message">
                      {field.fieldName === "email" && props.ckEmail}
                      {field.fieldName === "subject" && props.ckSubject}
                    </div>
                  </div>
                ) : (
                  <div className="text">
                    <label>{/*field.label*/}</label>
                    <textarea
                      className={field.classType}
                      placeholder={field.placeholder}
                      name={field.fieldName}
                      data-check={field.isRequired}
                      onChange={(e) => {
                        props.validateFields(e);
                        props.handleForm(e);
                      }}
                      value={field.name}
                    />
                    <div className={props.ckMessage ? "error-message" : "none"}>
                      {props.ckMessage && props.ckMessage}
                    </div>
                  </div>
                )}
              </div>
            );
          })}
        <div className={props.emailSent ? "message-sent" : "none"}>
          {props.emailSent && props.emailSent}
        </div>
        {/*
        <div
          className="g-recaptcha"
          data-sitekey={`${process.env.REACT_APP_CAPTCHA}`}
          data-callback={() => setButtonState("enabled")}
        ></div>
        <div id="html_element"></div>*/}
        <ReCAPTCHA
          sitekey={`${process.env.REACT_APP_CAPTCHA}`}
          ref={recaptchaRef}
          onChange={() => setButtonState("")}
        />
        <div className="button-container on">
          <button
            onClick={() => props.setEmailSent(false)}
            disabled={buttonState}
          >
            Envoyer
          </button>
        </div>
      </form>
    </div>
  );
};

export default ContactForm;
