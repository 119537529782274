import React, { useRef } from "react";
import { gsap } from "gsap";
import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
//import { useSwiper } from "swiper/react";
import swiperData from "../data/swiperData";
import Button from "./Button";
import "swiper/css/bundle";
import "swiper/css";
import "./styles/swiper.css";
import {
  Parallax,
  Navigation,
  Pagination,
  Mousewheel,
  Keyboard,
  Autoplay,
  EffectCreative,
  EffectFade,
} from "swiper";

const Slider = () => {
  const progressCircle = useRef(null);
  const progressContent = useRef(null);
  const onAutoplayTimeLeft = (s, time, progress) => {
    progressCircle.current.style.setProperty("--progress", 1 - progress);
    progressContent.current.textContent = `${Math.ceil(time / 1000)}s`;
  };

  const navigate = useNavigate();
  const gotoPage = (link) => {
    navigate(link);
  };

  const animationSlider = () => {
    const swiperAnimation5 = gsap.timeline();
    swiperAnimation5
      .to(".slider-container", {
        opacity: 0,
        duration: 1,
        ease: "sine.out",
      })
      .to(".slider-container", {
        duration: 1,
        opacity: 3,
        ease: "sine.in",
      });
    const swiperAnimation = gsap.timeline();
    swiperAnimation
      .to(".slider-title", {
        opacity: 0,
        duration: 1,
        ease: "sine.out",
        x: 400,
      })
      .to(".slider-title", {
        duration: 1,
        opacity: 1,
        //stagger: 0.3,
        ease: "sine.in",
        x: 0,
      });
    /*swiperAnimation.from(".slider-title", {
      // opacity: 0,
      duration: 1,
      ease: "power1.inOut",
      x: "400%",
      //    x: 1400,
      // ease: "slow(0.7, 0.7, false)",
      // ease: "elastic.out(1, 0.3)",
    });
    /*
      .to(".slider-title", {
        opacity: 1,
        duration: 2,

        ease: "power1.inOut",
        x: "400%",
        //ease: "slow(0.7, 0.7, false)",
        //ease: "elastic.out(1, 0.3)",
      });
    */
    const swiperAnimation2 = gsap.timeline();
    swiperAnimation2
      .to(".slider-text", {
        opacity: 0,
        scale: 0.1,
        duration: 1,

        ease: "sine.in",
      })
      .to(".slider-text", {
        opacity: 1,
        duration: 1,
        scale: 1,
      });

    /*
    const swiperAnimation4 = gsap.timeline();
    swiperAnimation4
      .to(".slider-button", {
        opacity: 0,
        duration: 1,
        ease: "bounce.in",
        x: 100,
      })
      .to(".slider-button", {
        opacity: 1,
        duration: 1,
        ease: "slide.in",
        x: 0,
      });*/
  };

  return (
    <div className="slider">
      <Swiper
        style={{
          "--swiper-navigation-color": "#fff",
          "--swiper-pagination-color": "#fff",
        }}
        onAutoplayTimeLeft={onAutoplayTimeLeft}
        //    rewind={true}
        speed={1200}
        // spaceBetween={30}
        //centeredSlides={true}
        //  parallax={true}
        onSlideChange={(swiper) => {
          animationSlider();
        }}
        onSwiper={(swiper) => {
          animationSlider();
        }}
        keyboard={{
          enabled: true,
        }}
        autoplay={{
          delay: 10000,
          disableOnInteraction: false,
        }}
        grabCursor={true}
        pagination={{
          clickable: true,
          dynamicBullets: true,
        }}
        //effect={"fade"}
        navigation={true}
        watchOverflow={true}
        modules={[
          Parallax,
          Navigation,
          Pagination,
          Mousewheel,
          Keyboard,
          Autoplay,
          EffectCreative,
          EffectFade,
        ]}
        className="mySwiper"
      >
        {swiperData.map((data) => {
          return (
            <SwiperSlide key={data.id} className={data.image}>
              {({ isActive }) => (
                <>
                  <div className="slider-container">
                    <div className="slider-background">
                      <div className="cube"></div>
                      <h1 className="slider-title">{data.title}</h1>
                      <h3 className="slider-subtitle">{data.subtitle}</h3>
                      <p className="slider-text">{data.description}</p>
                      <Button
                        packLink={data.link}
                        onClick={() => gotoPage(data.link)}
                      />
                    </div>
                  </div>
                </>
              )}
            </SwiperSlide>
          );
        })}
        {/*<span slot="container-start">Container Start</span>
        <span slot="container-end">Container End</span>
        <span slot="wrapper-start">Wrapper Start</span>
      <span slot="wrapper-end">Wrapper End</span>*/}
        <div className="autoplay-progress" slot="container-end">
          <svg viewBox="0 0 48 48" ref={progressCircle}>
            <circle cx="24" cy="24" r="20"></circle>
          </svg>
          <span ref={progressContent}></span>
        </div>
        {/*}     <div class="swiper-button-next">Next</div>
        <div class="swiper-button-prev">Prev</div>*/}
      </Swiper>
    </div>
  );
};
export default Slider;
