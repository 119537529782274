const packMData = [
  {
    id: 1,
    packName: "PackM-1",
    packTitle: "Forfait horaire",
    packPrice: "49.99€",
    packDetails: `Avec notre tarification basée sur un taux horaire fixe, vous ne payez que pour le temps réellement consacré à votre projet. Nous enregistrons avec précision chaque heure de travail effectuée, vous offrant ainsi une transparence totale sur les coûts. Profitez de la flexibilité et de la maîtrise de votre budget grâce à notre forfait horaire.`,
  },
  {
    id: 2,
    packName: "PackM-2",
    packTitle: "Forfait mensuel",
    packPrice: "279€ - 999€",
    packDetails: `Simplifiez la gestion de vos services en optant pour notre forfait mensuel qui regroupe une gamme complète de prestations adaptées à vos besoins. Que vous ayez besoin de services de développement web, de maintenance technique ou de marketing en ligne, notre forfait mensuel offre une solution pratique et rentable. Bénéficiez d'un tarif fixe mensuel et d'un accès illimité à nos services pour répondre à vos besoins évolutifs. Ne vous souciez plus des coûts variables, notre forfait mensuel vous offre une tranquillité d'esprit et une prévisibilité budgétaire.`,
    extra: [],
  },
  {
    id: 3,
    packName: "PackM-3",
    packTitle: "Forfait semestriel",
    packPrice: "139€/mois - 539€/mois",
    packDetails: `Avec ce forfait, vous bénéficiez de services de haute qualité tout au long de six mois, à un tarif préférentiel.\n Notre forfait semestriel est conçu pour vous offrir une flexibilité accrue et une tranquillité d'esprit. Profitez de ressources dédiées, d'un suivi régulier et d'une attention personnalisée pour mener à bien vos projets à long terme. Économisez sur les coûts tout en bénéficiant de  a qualité et de l'expertise de notre équipe.\n\n Que vous soyez une petite entreprise, une start-up ou une organisation établie, notre forfait semestriel s'adapte à vos besoins spécifiques.`,
    extra: [],
  },
  {
    id: 4,
    packName: "PackM-4",
    packTitle: "Forfait annuel",
    packPrice: "69€/mois - 269€/mois",
    packDetails: `Notre forfait annuel vous offre une solution complète et durable pour tous vos besoins en matière de développement web, de marketing en ligne  et bien plus encore. Avec un engagement sur une année complète, vous bénéficiez d'un tarif avantageux et d'un accès continu à nos services haut de gamme. Profitez de la continuité de service, de l'expertise de notre équipe et de la garantie de résultats durables tout au long de l'année.\n\n Que vous souhaitiez développer votre présence en ligne, optimiser vos performances ou obtenir un soutien technique constant, notre forfait annuel répondra à vos attentes. \n\nEn choisissant notre forfait annuel, vous économisez non seulement sur les coûts, mais vous investissez également dans la croissance à long terme de votre entreprise. `,
    extra: [
      "Services inclus dans le forfait :",
      "- 1 nom de domaine a le choix du client (si c’est possible, avec certain conditions)",
      "- 1 espace hébergement",
    ],
  },
  {
    id: 5,
    packName: "PackM-5",
    packTitle: "Forfait hébergement",
    packPrice: "79.99€/an",
    packDetails: `Obtenez un soutien professionnel et fiable pour votre hébergement avec notre forfait de support d'hébergement. Que vous ayez besoin d'une assistance technique, de résolution de problèmes ou de conseils d'experts, notre forfait de support d'hébergement est conçu pour répondre à vos besoins spécifiques. \n\nAvec notre forfait de support d'hébergement, vous bénéficiez d'une équipe dédiée prête à vous aider en cas de difficultés liées à votre hébergement. Nous sommes disponibles pour répondre à vos questions, vous guider dans les paramètres de votre compte, effectuer des ajustements de configuration et résoudre tout problème technique que vous pourriez rencontrer. \n\nNotre forfait de support d'hébergement offre également une surveillance proactive de votre site, avec des alertes en cas de problèmes de performances ou de sécurité. Nous nous assurons que votre hébergement reste sécurisé, stable et réactif afin que vous puissiez vous concentrer sur votre activité principale sans interruption.`,
    extra: [
      "Services inclus dans le forfait :",
      "- 1 nom de domaine a le choix du client (si c’est possible, avec certain conditions)",
      "- 1 espace hébergement",
    ],
  },
];
export default packMData;
