const socialData = [
  {
    id: 1,
    socialName: "What's Up",
    socialLink: `https://api.whatsapp.com/send/?phone=33634538034&text="General Inquiry"&type=phone_number&app_absent=0`,
    socialIcon: "media-wa",
  },
  {
    id: 2,
    socialName: "LinkedIn",
    socialLink: `https://www.linkedin.com/in/stefan-momir-59678722b`,
    socialIcon: "media-in",
  },
  {
    id: 3,
    socialName: "GitHub",
    socialLink: "https://github.com/StefanMomir",
    socialIcon: "media-gt",
  },
  {
    id: 4,
    socialName: "Twitter",
    socialLink: "",
    socialIcon: "media-tw",
  },
  {
    id: 5,
    socialName: "Facebook",
    socialLink: "",
    socialIcon: "media-fb",
  },
  {
    id: 6,
    socialName: "Instagram",
    socialLink: "",
    socialIcon: "media-ig",
  },
  {
    id: 7,
    socialName: "Youtube",
    socialLink: "",
    socialIcon: "media-yt",
  },
];

export default socialData;
