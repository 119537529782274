import React from "react";
import { NavLink } from "react-router-dom";
import navData from "../data/navData";
import logo from "../assets/small-logo.png";

const NavBar = () => {
  return (
    <nav className="navigation">
      <ul>
        {navData.map((filterNavTo) =>
          filterNavTo.mainNav.map((getNav) => (
            <li key={getNav.id}>
              <NavLink to={getNav.navLink}>
                {getNav.id !== 1 ? (
                  getNav.linkName
                ) : (
                  <>
                    <span className="material-icons small">home</span>
                    <img className="small-logo" src={logo} alt="top=logo" />
                  </>
                )}
              </NavLink>
            </li>
          ))
        )}
      </ul>
    </nav>
  );
};

export default NavBar;
