import "./styles/App.css";
import SiteRoutes from "./routes/SiteRoutes.jsx";
import Header from "./components/Header.jsx";
import Footer from "./components/Footer.jsx";

function App() {
  /* const dispatch = useDispatch();
  const sendData = () => {
    dispatch(getData("Hello from the first component"));
  };*/

  return (
    <div className="App">
      <Header />
      <SiteRoutes />
      <Footer />
    </div>
  );
}

export default App;
