export const softwareTopData = {
  softwareTitle: `Dépannage logiciel`,
  softwareDescription: `Notre forfait dépannage logiciel vous propose une assistance spécialisée pour résoudre rapidement vos problèmes informatiques. Que vous rencontriez des erreurs, des plantages ou des difficultés d'utilisation de vos logiciels, notre équipe d'experts est là pour vous aider.
  \n Choisissez notre forfait dépannage logiciel et bénéficiez d'une assistance professionnelle et efficace pour résoudre vos problèmes informatiques. Nous mettons tout en œuvre pour minimiser les interruptions et vous permettre de reprendre vos activités sans souci.`,
};

export const softwareData = [
  {
    id: 1,
    packName: "Pack-SW1",
    packTitle: "Forfait basique",
    packPrice: "40€ - 80€.",
    packDetails: `Avec notre tarification basée sur un taux horaire fixe, vous ne payez que pour le temps réellement consacré à votre projet. Nous enregistrons avec précision chaque heure de travail effectuée, vous offrant ainsi une transparence totale sur les coûts. Profitez de la flexibilité et de la maîtrise de votre budget grâce à notre forfait horaire.`,
    extra: [
      `Diagnostic et résolution des problèmes logiciels courants.`,
      `Mises à jour logicielles.`,
      `Optimisation des performances.`,
    ],
    order: "one",
  },
  {
    id: 2,
    packName: "Pack-SW2",
    packTitle: "Forfait avancé",
    packPrice: "80€ - 150€",
    packDetails: `Simplifiez la gestion de vos services en optant pour notre forfait mensuel qui regroupe une gamme complète de prestations adaptées à vos besoins. Que vous ayez besoin de services de développement web, de software technique ou de marketing en ligne, notre forfait mensuel offre une solution pratique et rentable. Bénéficiez d'un tarif fixe mensuel et d'un accès illimité à nos services pour répondre à vos besoins évolutifs. Ne vous souciez plus des coûts variables, notre forfait mensuel vous offre une tranquillité d'esprit et une prévisibilité budgétaire.`,
    extra: [
      `Diagnostic approfondi des problèmes logiciels complexes.`,
      `Résolution des erreurs système.`,
      `Suppression de logiciels malveillants et de virus.`,
      `Configuration et réparation des logiciels.`,
    ],
    order: "two",
  },
  {
    id: 3,
    packName: "Pack-SW3",
    packTitle: "Forfait installation et configuration ",
    packPrice: "50€ - 120€",
    packDetails: `Avec ce forfait, vous bénéficiez de services de haute qualité tout au long de six mois, à un tarif préférentiel.\n Notre forfait semestriel est conçu pour vous offrir une flexibilité accrue et une tranquillité d'esprit. Profitez de ressources dédiées, d'un suivi régulier et d'une attention personnalisée pour mener à bien vos projets à long terme. Économisez sur les coûts tout en bénéficiant de  a qualité et de l'expertise de notre équipe.\n\n Que vous soyez une petite entreprise, une start-up ou une organisation établie, notre forfait semestriel s'adapte à vos besoins spécifiques.`,
    extra: [
      `Installation de nouveaux logiciels.`,
      `Configuration des paramètres selon vos besoins.`,
      `Vérification de compatibilité et résolution de problèmes d'installation.`,
      `Formation de base à l'utilisation du logiciel.`,
    ],
    order: "three",
  },
  {
    id: 4,
    packName: "Pack-SW4",
    packTitle: "Forfait support à distance",
    packPrice: "30€ - 70€/h",
    packDetails: `Avec ce forfait, vous bénéficiez de services de haute qualité tout au long de six mois, à un tarif préférentiel.\n Notre forfait semestriel est conçu pour vous offrir une flexibilité accrue et une tranquillité d'esprit. Profitez de ressources dédiées, d'un suivi régulier et d'une attention personnalisée pour mener à bien vos projets à long terme. Économisez sur les coûts tout en bénéficiant de  a qualité et de l'expertise de notre équipe.\n\n Que vous soyez une petite entreprise, une start-up ou une organisation établie, notre forfait semestriel s'adapte à vos besoins spécifiques.`,
    extra: [
      `Assistance à distance pour résoudre les problèmes logiciels.`,
      `Aide à l'utilisation des logiciels et des fonctionnalités.`,
      `Conseils et astuces pour optimiser l'efficacité logicielle.`,
    ],
    order: "four",
  },
];
