const swiperData = [
  {
    id: 1,
    sliderName: "d",
    image: "img1",
    title: "Création de sites web",
    subtitle: "Votre présence en ligne professionnelle et efficace",
    button: "blue",
    classStyle: "",
    description: `En combinant les avantages de React JS avec notre expertise en design web, nous créons des sites web à la pointe de la technologie, optimisés pour les performances et adaptés à tous les appareils. Que ce soit pour un site vitrine, une application web ou un portail de commerce électronique, notre approche centrée sur l'utilisateur garantit une navigation intuitive et une expérience utilisateur fluide.`,
    link: "web/webservices",
  },
  {
    id: 2,
    sliderName: "d",
    image: "img2",
    title: "Maintenance web",
    subtitle:
      "Garantissez la performance et la sécurité continues de votre site",
    button: "blue",
    classStyle: "",
    description: `Assurez la fiabilité et l'efficacité de votre site web grâce à notre service de maintenance professionnelle. Nous prenons en charge les mises à jour régulières, les sauvegardes sécurisées, la surveillance proactive et la résolution rapide des problèmes. Notre équipe technique expérimentée est dédiée à maintenir votre site web à jour, à optimiser sa vitesse de chargement, à garantir sa compatibilité avec les navigateurs et à assurer la sécurité de vos données.`,
    link: "web/webservices/maintenance",
  },
  {
    id: 3,
    sliderName: "d",
    image: "img3",
    title: "Maintenance Informatique",
    subtitle: "Maintenez votre système informatique performant et sécurisé.",
    button: "blue",
    classStyle: "",
    description: `Optimisez la performance et la fiabilité de votre infrastructure informatique avec notre service de maintenance professionnel. Notre équipe qualifiée assure une surveillance proactive, des mises à jour régulières, des sauvegardes sécurisées et une assistance rapide en cas de besoin. Nous veillons à ce que votre système reste à jour, sécurisé et opérationnel, minimisant ainsi les interruptions et les perturbations. Faites confiance à notre expertise pour une maintenance préventive et réactive, garantissant ainsi une continuité fluide de vos activités informatiques`,
    link: "informatique/services/maintenance",
  },
  {
    id: 4,
    sliderName: "d",
    image: "img4",
    title: "Dépannage Logiciel",
    subtitle: "Profitez d'un service de dépannage logiciel complet et fiable.",
    button: "blue",
    classStyle: "",
    description: `Besoin d'une assistance rapide et efficace pour vos problèmes logiciels ? Notre équipe de techniciens expérimentés est là pour vous aider. Nous diagnostiquons et résolvons les problèmes logiciels, que ce soit des erreurs système, des plantages, des infections de logiciels malveillants ou des problèmes de compatibilité. Nous assurons également l'installation, la configuration et la mise à jour de vos logiciels pour optimiser leurs performances. Faites confiance à notre expertise pour un dépannage logiciel rapide et fiable, afin de maintenir votre système informatique en parfait état de fonctionnement.`,
    link: "informatique/services/logiciel",
  },
  {
    id: 5,
    sliderName: "d",
    image: "img5",
    title: "Dépannage Materiel",
    subtitle: "Solutions rapides pour vos problèmes matériels informatiques.",
    button: "blue",
    classStyle: "",
    description: `Notre équipe expérimentée vous offre un service de dépannage matériel de qualité. Que ce soit pour un ordinateur, un périphérique ou un réseau, nous sommes là pour résoudre vos problèmes. Nous diagnostiquons et réparons les pannes matérielles, remplaçons les composants défectueux et assurons la mise en place et la configuration de nouveaux matériels. Faites confiance à notre expertise pour un dépannage rapide et efficace afin de garantir le bon fonctionnement de votre équipement informatique.`,
    link: "informatique/services/materiel",
  },
];

export default swiperData;
