import React, { useState } from "react";
import axios from "axios";
import { config } from "../data/contactData.js";
import ContactForm from "../components/ContactForm";
import socialData from "../data/socialData.js";

const Contact = () => {
  const [formData, setFormData] = useState({
    email: "",
    message: "",
    subject: "",
  });
  const [ckEmail, setCkEmail] = useState(null);
  const [ckSubject, setCkSubject] = useState(null);
  const [ckMessage, setCkMessage] = useState(null);
  const [validated, setValidated] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const {
    title,
    details,
    title1,
    phone,
    address,
    zone,
    horaire,
    horaireTravail,
    fieldsConfig,
  } = config;

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validated) {
      axios({
        method: "post",
        url: `${process.env.REACT_APP_FORM_API}`,
        headers: { "content-type": "application/json" },
        data: formData,
      })
        .then((result) => {
          if (result.status === 200) {
            document.getElementById("form-reset").reset();
            setValidated(false);
            setEmailSent("Courriel Envoie !");
            setFormData([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const handleForm = (e) => {
    setEmailSent("");
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const validateFields = (e) => {
    e.preventDefault();
    !formData.email ? setCkEmail("Courriel manquant !") : setCkEmail(null);
    !formData.subject ? setCkSubject("Sujet manquant !") : setCkSubject(null);
    !formData.message ? setCkMessage("Message manquant !") : setCkMessage(null);
    if (formData.email && formData.subject && formData.message) {
      setValidated(true);
      setEmailSent("");
    }
  };

  return (
    <div className="contact">
      <div className="contact-container">
        <div className="cube"></div>

        <div className="contact-top-container">
          <div className="contact-header">
            <h1>{title}</h1>
            <h3>{details}</h3>
            <div className="contact-middle">&nbsp;</div>

            <div className="contact-bottom">
              <div className="contact-bottom-left">
                <p className="contact-title">{title1}</p>
                <p>{address}</p>
                <p className="horaire">{horaire}</p>
                <p className="horaire">{horaireTravail}</p>
                <p className="zone">{zone}</p>
                <p className="phone-icon">{phone}</p>
                <div className="contact-media">
                  {socialData
                    .filter((filterId) => filterId.id <= 3)
                    .map((getData) => (
                      <div key={getData.id} className="contact-socials">
                        <a href={getData.socialLink}>
                          <span className={getData.socialIcon} />
                        </a>
                      </div>
                    ))}
                  <div className="between"></div>
                  <div className="qr-code"></div>
                </div>
              </div>
              <ContactForm
                fieldsConfig={fieldsConfig}
                handleSubmit={handleSubmit}
                handleForm={handleForm}
                validateFields={validateFields}
                ckEmail={ckEmail}
                ckMessage={ckMessage}
                ckSubject={ckSubject}
                emailSent={emailSent}
                setEmailSent={setEmailSent}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
