import React from "react";
import SecondNavBar from "../components/SecondNavBar";
import packVData from "../data/packVData";

const PackV = () => {
  return (
    <div className="pack creation">
      <SecondNavBar showMore={true} data={"web"} />
      <div className="pack-container">
        <div className="packs-elements">
          <div className="web-cube-offers title"></div>
          <div className="packs-title">
            <h1>Creation site web vitrine</h1>
          </div>
          <div className="packs-details">
            {packVData.map((getData) => {
              return (
                <div key={getData.id} className="details-container ">
                  <h3>
                    {getData.packName}
                    <span className="title"> - {getData.packTitle} -</span>
                    <span className="price">
                      {getData.packPrice}
                      &nbsp;{getData.packCount}
                    </span>
                  </h3>
                  <p>{getData.packDetails}</p>
                  <p className="sub-pack">Cet pack comprend les versions :</p>
                  <div className="sub-container">
                    {getData.packVersion.map((getData) => {
                      return (
                        <div key={getData.id} className="sub-title-content">
                          <h3 className="sub-pack-title">
                            - {getData.subPack} -
                            <span className="sub-pack-price">
                              &nbsp;{getData.subPackPrice}
                            </span>
                          </h3>

                          <p className="sub-pack-details-title">
                            Inclut dans le pack :
                          </p>
                          {getData.subPackDetails.map((getData, index) => (
                            <p key={index} className="sub-pack-details">
                              {getData}
                            </p>
                          ))}
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PackV;
