const mainData = {
  webServiceTitle: `Services web`,
  webServiceSubTitle: `Transformez votre présence en ligne avec nos services web. Grâce à nos services web personnalisés, nous vous proposons des solutions sur mesure pour créer, développer et optimiser votre site web. De la conception à la mise en ligne, nous mettons tout en œuvre pour garantir une expérience utilisateur exceptionnelle. Avec notre expertise et notre passion pour le web, découvrez comment nous pouvons vous aider à atteindre vos objectifs en ligne et propulser votre entreprise vers de nouveaux sommets.`,
  webServiceDescription: `Bienvenue dans notre section dédiée aux services web. Nous sommes spécialisés dans la création de sites web dynamiques et interactifs en utilisant la technologie React. Grâce à notre expertise en  développement web réactif, nous pouvons créer des expériences utilisateur fluides et optimisées pour les appareils mobiles et de bureau. Que vous ayez besoin d'un site vitrine, d'une application web ou d'une plateforme de commerce électronique, notre équipe est prête à transformer votre vision en réalité. Faites confiance à notre savoir-faire en React pour obtenir un site web moderne, réactif et performant.`,
  serviceTitle: `Services informatiques`,
  serviceSubTitle: `Découvrez nos services informatiques de confiance. Nous proposons une gamme complète de solutions pour répondre à vos besoins technologiques. Que ce soit pour la maintenance et le dépannage, l'installation de logiciels, la gestion de réseaux ou la sécurité informatique, notre équipe expérimentée est là pour vous aider. Faites confiance à notre expertise pour vous fournir des solutions efficaces et fiables, afin que vous puissiez vous concentrer sur votre activité principale.`,
  serviceDescription: `Notre service de dépannage logiciel, matériel et maintenance est conçu pour vous aider à tirer le meilleur parti de vos systèmes informatiques. Que vous rencontriez des problèmes avec vos logiciels, votre matériel ou que vous ayez besoin d'une maintenance régulière pour garantir leur bon fonctionnement, notre équipe d'experts est là pour vous accompagner.

Pour le dépannage logiciel, nos techniciens sont compétents pour résoudre les erreurs système, supprimer les logiciels malveillants, résoudre les problèmes de compatibilité, réparer les logiciels corrompus et mettre à jour les pilotes. Ils peuvent également vous aider à configurer votre système d'exploitation et à récupérer des données perdues.

En ce qui concerne le dépannage matériel, nos spécialistes sont en mesure de diagnostiquer et de réparer les problèmes matériels, de remplacer les composants défectueux, d'effectuer des mises à niveau matérielles et de procéder au nettoyage et à l'entretien de votre équipement. Ils peuvent également vous assister dans l'installation de périphériques et résoudre les problèmes de connectivité.

Enfin, notre service de maintenance préventive vous offre la possibilité de planifier des interventions régulières pour assurer la stabilité et les performances optimales de vos systèmes. Nous effectuons des vérifications, des mises à jour et des optimisations pour prévenir les problèmes potentiels et minimiser les interruptions de service.

Avec notre service de dépannage logiciel, matériel et maintenance, vous pouvez avoir l'assurance que votre infrastructure informatique est entre de bonnes mains, vous permettant de vous concentrer sur votre activité principale en toute tranquillité.`,
};

export default mainData;
