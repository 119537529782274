const contractsData = [
  {
    id: 1,
    typeContract: "packV",
    titleContract: "Contrat de création de page web vitrine",
    detailsContract: [
      { id: "c1", detailObjectTitle: "1" },
      { id: "c2", detailContentTitle: "2" },
      { id: "c3", detailContentTitle: "3" },
      { id: "c4", detailContentTitle: "4" },
      { id: "c5", detailContentTitle: "5" },
    ],
  },
  {
    id: 2,
    typeContract: "packM",
    titleContract: "Maintenance web",
    detailsContract: [
      "Objet du contrat",
      "Contenu de la page web",
      "Design et mise en page",
      "Développement technique",
      "Contenu dynamique",
      "Délais et livraison",
      "Révisions et corrections",
      "Droits de propriété",
      "Confidentialité ",
      "Paiement",
    ],
  },
  {
    id: 3,
    typeContract: "packM",
    titleContract: "Méthodes de facturation",
    detailsContract: [
      "Forfait horaire",
      "Forfait mensuel",
      "Forfait semestriel",
      "Forfait annuel",
      "Forfait support hébergement",
    ],
    extra: `Toutes les forfait inclure : correction 
    des erreurs ou des bogues, la gestion des problèmes 
    techniques, avec certain conditions.(*1)`,
  },
  {
    id: 4,
    typeContract: "packM",
    titleContract: "Types de contrats",
    detailsContract: [
      "PackM-1 - 49.99€/h",
      "PackM-2 - 279€ - 999€",
      "PackM-3 - 139€ - 538€/mois",
      "PackM-4 - 69€ - 269€/mois",
      "PackM-5 - 79.99€/an",
    ],
  },
  {
    id: 5,
    typeContract: "packM",
    titleContract: "Plan tarifaire",
    detailsContract: [
      "Développement et programmation",
      "Correction des erreurs ou des bugs",
      "Conception graphique et intégration visuelle",
      "Optimisation des performances",
      "Contenu dynamique",
      "Gestion de contenu et mises à jour",
      "Sécurité et sauvegardes",
    ],
  },
];
export default contractsData;
