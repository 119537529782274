const navData = [
  {
    mainNav: [
      { id: 1, icon: "", navLink: "/", linkName: "Home" },
      {
        id: 2,
        icon: "",
        navLink: "/informatique/services",
        linkName: "Services Informatiques",
      },
      {
        id: 3,
        icon: "",
        navLink: "/web/webservices",
        linkName: "Services web",
      },
      /*{ id: 2, navLink: "/services", linkName: "Nos Services" },*/
      { id: 4, icon: "", navLink: "/contact", linkName: "Contact" },
    ],
    secondNav: [
      {
        id: 1,
        icon: "",
        navLink: "/web/webservices/creation",
        linkName: "Creation Site Web",
        navClass: "one",
      },
      {
        id: 2,
        icon: "",
        navLink: "/web/webservices/maintenance",
        linkName: "Maintenance Web",
        navClass: "two",
      },
    ],
    thirdNav: [
      {
        id: 1,
        icon: "",
        navLink: "/informatique/services/logiciel",
        linkName: "Dépannage logiciel",
        navClass: "one",
      },
      {
        id: 2,
        icon: "",
        navLink: "/informatique/services/materiel",
        linkName: "Dépannage matériel",
        navClass: "two",
      },
      {
        id: 3,
        icon: "",
        navLink: "/informatique/services/maintenance",
        linkName: "Maintenance",
        navClass: "three",
      },
    ],
    /* id: 2,
    secondNav: [
      { id: 1, navLink: "/", linkName: "Dépannage matériel" },
      { id: 2, navLink: "/services", linkName: "Dépannage logiciel" },
      {
        id: 3,
        navLink: "/installation",
        linkName: "Installation et configuration",
      },
      { id: 4, navLink: "/recuperation", linkName: "Récupération de données" },
      { id: 5, navLink: "/maintenance", linkName: "Maintenance préventive" },
      { id: 6, navLink: "/développement", linkName: "Création de sites web" },
    ],
    */
  },
];

export default navData;
