import React, { useState } from "react";
import webData from "../data/webData";
import SecondNavBar from "../components/SecondNavBar";
import mainData from "../data/mainData";
import Button from "../components/Button";

const WebServices = () => {
  let [showMore, setShowMore] = useState(true);

  const { webServiceTitle, webServiceSubTitle, webServiceDescription } =
    mainData;

  return (
    <div className="web">
      <div className="web-container">
        <SecondNavBar showMore={showMore} data={"web"} />
        <div className={showMore === false ? "web-box-container" : "out"}>
          <h1 className="web-title-top">{webServiceTitle}</h1>
          <h3>{webServiceSubTitle}</h3>
          <div className="web-cube"></div>
          <div className="web-cube-right"></div>
          <div className="web-cube-left"></div>
          <div className="web-cube-bottom"></div>
          <div className="web-button">
            <button onClick={() => setShowMore(true)}>Découvrir</button>
          </div>
        </div>
        <div className="web-box-offers-container">
          <div
            className={
              showMore === true ? "web-offers-intro-container" : "hide"
            }
          >
            <div className="web-offers-intro">
              <h1>{webServiceTitle}</h1>
              <h3>{webServiceDescription}</h3>
              <div className="web-cube-offers-intro"></div>
            </div>
          </div>
          <div className="web-offers-details">
            {webData.map((getData) => {
              return (
                <div
                  className={showMore === true ? "web-box-offers" : "hide"}
                  key={getData.id}
                >
                  <div className={getData.packageClass}>
                    <div className="web-title">
                      <h1>{getData.packageTitle}</h1>
                    </div>
                    <div className="web-content-details">
                      {getData.packageSubTitle && (
                        <h3>{getData.packageSubTitle}</h3>
                      )}
                      {getData.packageDetails.map((getContent, index) => (
                        <div className="web-box-details" key={index}>
                          <p className={index < 0 ? "" : "web-line"}>
                            {getContent}
                          </p>
                        </div>
                      ))}
                    </div>
                    {getData.packageButton && (
                      <Button packLink={getData.packageLink} />
                    )}
                    <div className="web-price">
                      <p>{getData.packagePrice}</p>
                    </div>
                    <div className="web-cube-offers-right"></div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WebServices;
