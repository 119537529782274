const tarifsMData = [
  {
    id: 1,
    packName: "PackM-T1",
    packTitle: "Développement et programmation",
    packPrice: "49.99€/h",
    packCount: "",
    packDetails: `Cette tarification s'applique aux tâches de développement personnalisé, de programmation de fonctionnalités spécifiques, de création de nouveaux modules ou plugins, etc.`,
  },
  {
    id: 2,
    packName: "PackM-T2",
    packTitle: "Correction des erreurs ou des bugs",
    packPrice: "49.99€/h",
    packCount: "(entre 1h - 4h)",
    packDetails: `Tarif en fonction de la complexité du bug.`,
  },
  {
    id: 3,
    packName: "PackM-T3",
    packTitle: "Conception graphique et intégration visuelle",
    packPrice: "49.99€/h",
    packCount: "(jusque 20h)",
    packDetails: `Ce tarif est utilisé pour les services de conception graphique tels que la création de maquettes, l'intégration de designs visuels, la création de logos, etc.`,
  },
  {
    id: 4,
    packName: "PackM-T4",
    packTitle: "Optimisation des performances",
    packPrice: "59.99€/h",
    packCount: "(jusque 10h)",
    packDetails: `Cette tarification s'applique aux tâches visant à améliorer les performances du site web, y compris l'optimisation des temps de chargement, la gestion du cache, la compression des images, etc.`,
  },
  {
    id: 5,
    packName: "PackM-T5",
    packTitle: "Gestion de contenu et mises à jour",
    packPrice: "39.99€/h",
    packCount: "(jusque 5h)",
    packDetails: `Ce tarif est utilisé pour les tâches de gestion du contenu, y compris l'ajout de nouveaux contenus, la modification des pages existantes, la mise à jour des informations, etc.`,
  },
  {
    id: 6,
    packName: "PackM-T6",
    packTitle: "Sécurité et sauvegardes",
    packPrice: "49.99€/h",
    packCount: "(jusque 10h)",
    packDetails: `Cette tarification s'applique aux tâches de sécurité du site web, y compris la configuration des pare-feu, les analyses de sécurité, les sauvegardes régulières, la gestion des certificats SSL, etc.`,
  },
];
export default tarifsMData;
