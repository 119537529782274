//import { useState, useEffect } from "react";
import logo from "../assets/logo_150x69.png";
import { NavLink } from "react-router-dom";

const Logo = () => {
  /*
  const [isVisible, setIsVisible] = useState(true);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    window.addEventListener("scroll", listenToScroll);
    return () => window.removeEventListener("scroll", listenToScroll);
  }, []);

  const listenToScroll = () => {
    let heightToHideFrom = 0;
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;
    setHeight(winScroll);

    if (winScroll > heightToHideFrom) {
      isVisible && setIsVisible(false);
    } else {
      setIsVisible(true);
    }
  };
*/

  return (
    <NavLink to="/">
      <div className="logo">
        <div className="image-container">
          {/* <div className="image-logo"></div>*/}
          <img src={logo} alt="top=logo" />
        </div>
      </div>{" "}
    </NavLink>
  );
};

export default Logo;
