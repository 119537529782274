import React from "react";
import { NavLink } from "react-router-dom";
import navData from "../data/navData";
import { AuthUser } from "../contexts/Auth";

export const SecondNavBar = ({ ...props }) => {
  const { onWeb } = AuthUser();
  return (
    <nav
      className={
        props.showMore === true || onWeb === true ? "web nav on" : "web nav"
      }
    >
      <ul>
        {navData.map((filterNavTo) => {
          return props.data === "web"
            ? filterNavTo.secondNav.map((getNav) => (
                <li key={getNav.id} className={getNav.navClass}>
                  <NavLink to={getNav.navLink}>{getNav.linkName}</NavLink>
                </li>
              ))
            : props.data === "service" &&
                filterNavTo.thirdNav.map((getNav) => (
                  <li key={getNav.id} className={getNav.navClass}>
                    <NavLink to={getNav.navLink}>{getNav.linkName}</NavLink>
                  </li>
                ));
        })}
      </ul>
    </nav>
  );
};

export default SecondNavBar;
