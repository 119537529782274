import React from "react";
import NavBar from "./NavBar.jsx";
import Logo from "./Logo.jsx";
import JumpUp from "../components/JumpUp.jsx";

const Header = () => {
  return (
    <header>
      <Logo />
      <NavBar />
      <JumpUp />
    </header>
  );
};

export default Header;
