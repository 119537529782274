export const maintenanceTopData = {
  maintenanceTitle: `Maintenance`,
  maintenanceDescription: `Notre forfait maintenance prend en charge votre infrastructure informatique. Avec notre équipe d'experts, vous bénéficiez d'une surveillance proactive, de mises à jour régulières et d'une assistance technique fiable. Optez pour notre forfait maintenance et assurez la stabilité de votre environnement informatique.`,
};

export const maintenanceData = [
  {
    id: 1,
    packName: "PackSV-M1",
    packTitle: "Forfait horaire",
    packPrice: "40€ - 120€/heure",
    packLink: "",
    packDetails: `Avec notre forfait horaire, vous avez la flexibilité de bénéficier d'une assistance informatique ponctuelle, selon vos besoins. Notre équipe d'experts est disponible pour résoudre vos problèmes et vous guider dans vos besoins informatiques. Vous ne payez que pour le temps réellement utilisé, vous offrant ainsi un contrôle total sur vos dépenses.`,
    extra: [
      `Pour les particuliers, le forfait horaire est de 40€.`,
      `Pour les petites entreprises, les prix horaires peuvent varier entre 40€ et 80€.`,
      `Pour les entreprises de taille moyenne, les prix horaires peuvent aller de 80€ à 120€.`,
      `Pour les grandes entreprises, les prix horaires peuvent dépasser les 120€.`,
    ],
    order: "one",
  },
  {
    id: 2,
    packName: "PackSV-M2",
    packTitle: "Forfait journalier",
    packPrice: "320€ - 960€/jour",
    packLink: "",
    packDetails: `Optez pour notre forfait journalier et bénéficiez d'une assistance informatique complète, jour après jour. Notre équipe d'experts est là pour résoudre vos problèmes informatiques et assurer le bon fonctionnement de votre système, vous permettant de vous concentrer sur votre activité principale en toute tranquillité.`,
    extra: [
      `Pour les particuliers, le forfait journalier est de 320€.`,
      `Pour les petites entreprises, les prix journaliers peuvent varier entre 320€ et 640€.`,
      `Pour les entreprises de taille moyenne, les prix journaliers peuvent aller de 640€ à 960€.`,
      `Pour les grandes entreprises, les prix journaliers peuvent dépasser les 960€.`,
    ],
    order: "two",
  },
  {
    id: 3,
    packName: "PackSV-M3",
    packTitle: "Forfait mensuel",
    packPrice: "800€ - 2400€/mois",
    packLink: "",
    packDetails: `Avec une tarification simple et transparente, vous bénéficiez d'une assistance professionnelle et proactive tout au long du mois. Notre équipe d'experts est disponible pour résoudre vos problèmes, effectuer des mises à jour, assurer la sécurité de vos données et optimiser les performances de votre infrastructure et profitez d'une tranquillité d'esprit totale.`,
    extra: [
      `Pour les particuliers, le forfait mensuel est de 800€.`,
      `Pour les petites entreprises, les prix mensuels peuvent varier entre 800€ et 1600€.`,
      `Pour les entreprises de taille moyenne, les prix mensuels peuvent aller de 1600€ à 2400€.`,
      `Pour les grandes entreprises, les prix mensuels peuvent dépasser les 2400€.`,
    ],
    order: "three",
  },
  {
    id: 4,
    packName: "PackSV-M4",
    packTitle: "Forfait annuel",
    packPrice: "à partir de 9600€/an",
    packLink: "",
    packDetails: `Profitez d'une tranquillité informatique tout au long de l'année. Notre équipe d'experts se charge de la maintenance, des mises à jour et de la sécurité de votre système, vous permettant de vous concentrer sur votre activité principale. Bénéficiez d'une assistance professionnelle continue et assurez-vous une année de fonctionnement informatique sans tracas.`,
    extra: [
      `Pour les particuliers, le forfait annuel est de 9 600€.`,
      `Pour les petites entreprises, les prix annuels peuvent varier entre 9 600€ et 19 200€.`,
      `Pour les entreprises de taille moyenne, les prix annuels peuvent aller de 19 200€ à 28 800€.`,
      `Pour les grandes entreprises, les prix annuels peuvent dépasser les 28 800€.`,
    ],
    order: "four",
  },
];
