import React from "react";
import { Routes, Route } from "react-router-dom";
import Contact from "../pages/Contact.jsx";
import Home from "../pages/Home.jsx";
//import Error404 from "../pages/Error404.jsx";
import PackV from "../pages/PackV.jsx";
import PackM from "../pages/PackM.jsx";
import WebServices from "../pages/WebServices.jsx";
import Services from "../pages/Services.jsx";
import Software from "../pages/Software.jsx";
import Hardware from "../pages/Hardware.jsx";
import Maintenance from "../pages/Maintenance.jsx";

const SiteRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/informatique">
        <Route path="services" element={<Services />} />
        <Route path="services/logiciel" element={<Software />} />
        <Route path="services/materiel" element={<Hardware />} />
        <Route path="services/maintenance" element={<Maintenance />} />
      </Route>
      <Route path="/web">
        <Route path="webservices" element={<WebServices />} />
        <Route path="webservices/creation" element={<PackV />} />
        <Route path="webservices/maintenance" element={<PackM />} />
      </Route>
      <Route path="/contact" element={<Contact />} />
      <Route path="*" element={<Home />} />
    </Routes>
  );
};

export default SiteRoutes;
