export const hardwareTopData = {
  hardwareTitle: `Dépannage matériel`,
  hardwareDescription: `Nos forfaits dépannage matériel sont conçus pour vous offrir une solution complète et pratique pour résoudre les problèmes liés à votre équipement informatique. Que ce soit un ordinateur, une imprimante, un périphérique ou tout autre matériel, notre équipe d'experts est là pour vous aider.
  \n Avec nos forfaits dépannage matériel, vous bénéficiez d'une assistance professionnelle et rapide. Nous diagnostiquons les problèmes, effectuons les réparations nécessaires, remplaçons les composants défectueux et nous assurons que votre équipement fonctionne à nouveau de manière optimale.`,
};

export const hardwareData = [
  {
    id: 1,
    packName: "Pack-HW1",
    packTitle: "Forfait basique",
    packPrice: "40€ - 80€.",
    packDetails: `Avec notre tarification basée sur un taux horaire fixe, vous ne payez que pour le temps réellement consacré à votre projet. Nous enregistrons avec précision chaque heure de travail effectuée, vous offrant ainsi une transparence totale sur les coûts. Profitez de la flexibilité et de la maîtrise de votre budget grâce à notre forfait horaire.`,
    extra: [
      `Diagnostic et résolution des problèmes matériels courants.`,
      `Nettoyage et maintenance de base.`,
    ],
    order: "one",
  },
  {
    id: 2,
    packName: "Pack-HW2",
    packTitle: "Forfait avancé",
    packPrice: "80€ - 150€",
    packDetails: `Simplifiez la gestion de vos services en optant pour notre forfait mensuel qui regroupe une gamme complète de prestations adaptées à vos besoins. Que vous ayez besoin de services de développement web, de software technique ou de marketing en ligne, notre forfait mensuel offre une solution pratique et rentable. Bénéficiez d'un tarif fixe mensuel et d'un accès illimité à nos services pour répondre à vos besoins évolutifs. Ne vous souciez plus des coûts variables, notre forfait mensuel vous offre une tranquillité d'esprit et une prévisibilité budgétaire.`,
    extra: [
      `Diagnostic approfondi des problèmes matériels complexes.`,
      `Réparation ou remplacement de composants défectueux.`,
      `Mise à jour du firmware ou du BIOS.`,
      `Configuration et réparation des logiciels.`,
    ],
    order: "two",
  },
  {
    id: 3,
    packName: "Pack-HW3",
    packTitle: "Forfait Installation et Configuration",
    packPrice: "50€ - 120€",
    packDetails: `Avec ce forfait, vous bénéficiez de services de haute qualité tout au long de six mois, à un tarif préférentiel.\n Notre forfait semestriel est conçu pour vous offrir une flexibilité accrue et une tranquillité d'esprit. Profitez de ressources dédiées, d'un suivi régulier et d'une attention personnalisée pour mener à bien vos projets à long terme. Économisez sur les coûts tout en bénéficiant de  a qualité et de l'expertise de notre équipe.\n\n Que vous soyez une petite entreprise, une start-up ou une organisation établie, notre forfait semestriel s'adapte à vos besoins spécifiques.`,
    extra: [
      `Installation de nouveaux composants matériels (disque dur, mémoire, carte graphique, etc.)`,
      `Configuration et mise en place des pilotes nécessaires.`,
      `Vérification de compatibilité matérielle.`,
    ],
    order: "three",
  },
  {
    id: 4,
    packName: "Pack-HW4",
    packTitle: "Forfait Maintenance Préventive",
    packPrice: "40€ - 80€/h",
    packDetails: `Avec ce forfait, vous bénéficiez de services de haute qualité tout au long de six mois, à un tarif préférentiel.\n Notre forfait semestriel est conçu pour vous offrir une flexibilité accrue et une tranquillité d'esprit. Profitez de ressources dédiées, d'un suivi régulier et d'une attention personnalisée pour mener à bien vos projets à long terme. Économisez sur les coûts tout en bénéficiant de  a qualité et de l'expertise de notre équipe.\n\n Que vous soyez une petite entreprise, une start-up ou une organisation établie, notre forfait semestriel s'adapte à vos besoins spécifiques.`,
    extra: [
      `Nettoyage en profondeur du matériel pour prévenir les problèmes de poussière et de surchauffe.`,
      `Vérification de l'état général du matériel.`,
      `Mise à jour des pilotes et du système d'exploitation.`,
    ],
    order: "four",
  },
];
